var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'draggable-question': true,
    'is-double': _vm.hands.length <= 2,
    'is-quad': _vm.hands.length >= 4,
    'is-repeat': _vm.isRepeat,
  }},[_c('div',{ref:"dropzone",class:{
      'draggable-question__dropzone': true,
      'is-focused': _vm.handInRange,
    }},[_c('TimerShape',{attrs:{"type":_vm.isRepeat ? 'cloud' : 'circle',"duration":_vm.timer,"start":_vm.isActive,"delay":2,"paused":_vm.activeModals.length > 0 || _vm.isMobileLandscape,"animate":_vm.activeHand !== false},on:{"expire":_vm.skipAnswer}}),_c('div',{staticClass:"draggable-question__help",attrs:{"aria-label":_vm.$l10n('drag-help-aria')}},[(_vm.isRepeat)?_c('strong',[_vm._v(_vm._s(_vm.labels.remember))]):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.labels['drag-help']))])])],1),_c('div',{staticClass:"draggable-question__hands"},_vm._l((_vm.hands),function(hand,id){return _c('BaseDraggableHand',_vm._b({key:id,attrs:{"target":_vm.$refs.dropzone,"enabled":_vm.isActive && (_vm.activeHand === id || _vm.activeHand === false),"margin":-0.3,"with-holster":_vm.useHolsters},on:{"update":_vm.updateActiveHand,"drag":_vm.updateHandInRange,"drop":_vm.validateHandDrop}},'BaseDraggableHand',hand,false))}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }