import Vue from 'vue';
import { forOwn } from 'lodash';
import { mapMutations } from 'vuex';
import PortalVue from 'portal-vue';
import { Howler } from 'howler';

import {
  id as surveyId,
  language,
  name as surveyName,
  config,
  translations,
  main_questions,
  followup_interfaces,
  recovery_questions,
  interrupt_questions,
  intro_survey,
  demographic_survey,
  levels,
  languages,
  share_config,
  surveyLanguage,
} from './config.js';

import store from './utilities/store.js';
import fixText from './utilities/text.js';
import l10n from './utilities/l10n.js';
import baseUrl from './utilities/url.js';
import logging from './utilities/logging.js';
import sounds from './utilities/sounds.js';
import App from '../framework/components/App.vue';

const BASE_URL = process.env.VUE_APP_BASE_URL || process.env.BASE_URL;

Vue.config.productionTip = false;
Vue.config.devtools = true;

Vue.use(PortalVue);
Vue.use(fixText);
Vue.use(l10n, translations);
Vue.use(baseUrl, BASE_URL);
Vue.use(logging, surveyName, store);

// Vue.prototype.$meta = $meta

Vue.mixin({
  methods: mapMutations(['showModal', 'hideModal']),
});

store.state.id = surveyId;
store.state.language = language;
store.state.baseURL = BASE_URL;
store.state.languages = languages;
store.state.survey = {
  ...config,
  id: surveyId,
  main_questions,
  followup_interfaces,
  translations,
  recovery_questions,
  interrupt_questions,
  intro_survey,
  demographic_survey,
  levels: levels?.map((level, i) => ({ index: i, ...level })),
  languages,
  share_config,
  surveyLanguage,
};

Howler.volume(store.state.useAudio ? 1 : 0);

// Handle auto-muting when focus leaves/returns
var hiddenProp, visibilityChange;
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hiddenProp = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hiddenProp = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hiddenProp = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

document.addEventListener(visibilityChange, function () {
  if (document[hiddenProp]) {
    Howler.volume(0);
  } else {
    if (store.state.useAudio) {
      Howler.volume(1);
    }
  }
});

export default function init({ soundEffects, ...appConfig }, components) {
  // Globally register all provided components
  forOwn(components, (component, name) => {
    Vue.component(name, component);
  });

  if (soundEffects) {
    Vue.use(sounds, soundEffects);
  }

  const props = {
    ...config,
    main_questions,
    followup_interfaces,
    recovery_questions,
    interrupt_questions,
    intro_survey,
    demographic_survey,
    levels: levels?.map((level, i) => ({ index: i, ...level })),
    languages,
    ...appConfig,
  };

  new Vue({
    store,
    el: '#app',
    render: h => h(App, { props }),
  });
}
