<template>
  <BaseScreen id="homescreen" name="home" class="homescreen">
    <div class="homescreen__hand homescreen__hand--thumbup"></div>
    <div class="homescreen__hand homescreen__hand--thumbdown"></div>
    <div class="homescreen__header">
      <img :src="burst" />
      <div class="homescreen__text">
        <h1 v-content="title" autofocus class="homescreen__title" />
        <p v-if="tagline" v-content="tagline" class="homescreen__tagline" />
      </div>
    </div>
    <div class="homescreen__body">
      <BaseButton
        ref="startButton"
        name="start"
        location="home"
        is-primary
        is-large
        data-cy="start"
        @click="$emit('next')"
        >{{ buttonText || $l10n('start') }}
      </BaseButton>
    </div>
  </BaseScreen>
</template>

<script>
import burst from '../assets/img/simphiwe/burst.svg';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    tagline: String,
    buttonText: String,
  },
  data() {
    return { burst };
  },
  mounted() {
    // setTimeout(() => {
    //   this.$refs.startButton.$el.focus();
    // }, 1000);
  },
};
</script>
