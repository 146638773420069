<template functional>
  <div>
    <svg
      v-show="props.isLeft"
      :class="{
        hand: true,
        ['is-color-' + props.color]: true,
        'is-open': props.isOpen,
        'is-left': props.isLeft,
      }"
      style="width: 150px; height: 200x"
      viewBox="0 0 500 550"
      preserveAspectRatio="xMinYMin"
    >
      <g class="hand--closed">
        <svg
          width="449"
          height="405"
          viewBox="0 0 449 405"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M386.114 334.579L382.444 333.915C397.636 329.603 411.428 320.454 418.595 306.521C425.656 292.791 424.909 270.825 404.597 266.026L401.871 265.388C401.941 265.267 401.945 265.119 401.989 264.983C418.181 262.137 432.363 249.633 438.113 234.015C440.219 228.353 441.207 222.335 441.022 216.296C440.787 209.815 438.732 203.531 435.093 198.162C431.453 192.794 426.377 188.558 420.443 185.94C422.17 185.575 423.862 185.056 425.496 184.388C436.835 179.688 443.73 167.446 445.515 155.255C447.299 143.064 451.068 122.157 446.057 110.996C433.664 83.2411 412.623 53.184 324.98 53.8059C252.25 54.3193 175.589 41.8597 137.614 34.7148C130.8 33.4581 123.801 33.618 117.052 35.1846C110.303 36.7512 103.948 39.6909 98.3852 43.8205C63.7789 72.2803 11.7911 98.3258 2.17031 150.83C1.86686 153.795 1.31618 156.849 0.942008 160.097C-4.16934 204.35 24.9402 246.791 33.3508 276.103C41.7614 305.416 24.443 311.652 66.0347 369.613L66.2901 369.911C82.1119 379.427 100.508 384.424 118.714 388.631C119.548 388.766 120.386 388.915 121.243 389.109C162.3 398.437 204.139 407.109 246.284 404.352C260.919 403.43 275.408 400.903 289.491 396.816C295.513 397.706 301.574 398.388 307.661 398.784C327.44 400.066 347.768 398.177 366.14 390.435C376.852 385.881 387.21 379.021 392.962 368.798C398.715 358.574 398.71 344.542 386.114 334.579Z"
            fill="#A9563C"
          />
          <path
            d="M366.515 89.5061C374.583 80.7206 385.278 74.7832 397.001 72.5819"
            stroke="#452015"
            stroke-width="3.02"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M236.431 310.618C223.577 304.882 215.481 289.965 217.59 275.872C210.932 281.056 205.57 287.716 201.925 295.326C198.281 302.937 196.454 311.29 196.589 319.727C197.02 336.5 205.957 352.721 220.657 360.619L222.037 361.289C217.394 353.47 217.835 343.367 221.023 334.746C224.21 326.125 229.855 318.668 235.926 311.712C235.857 311.672 235.766 311.63 236.431 310.618Z"
            fill="#452015"
          />
          <path
            d="M198.057 124.264C194.232 188.889 148.55 249.452 87.6353 270.76"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M216.551 341.252C211.887 357.45 230.35 376.931 244.81 385.165C259.271 393.398 276.209 396.041 292.766 398.002C307.492 399.738 322.566 402.067 337.136 399.151C355.464 395.507 375.603 387.366 386.565 377.159"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M253.639 170.672C248.714 173.536 244.405 177.347 240.961 181.884C237.517 186.422 235.005 191.597 233.572 197.111C232.196 202.611 231.935 208.332 232.803 213.935C233.67 219.538 235.65 224.911 238.625 229.739"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M236.704 245.084C231.223 248.772 226.709 253.723 223.541 259.52C220.373 265.317 218.644 271.79 218.5 278.395C218.466 284.949 220.036 291.411 223.074 297.219C226.111 303.026 230.524 308.002 235.927 311.712"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M36.218 296.257C40.8575 342.941 56.9273 371.988 97.8859 383.245"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M263.549 92.0084C254.379 92.3048 245.406 94.7417 237.346 99.1245C229.286 103.507 222.362 109.715 217.129 117.25C211.365 125.654 207.834 135.809 208.404 145.942C208.974 156.074 213.983 166.079 222.418 171.549C230.853 177.02 242.695 177.309 250.9 171.297C244.749 166.529 239.9 160.287 236.802 153.147C233.704 146.008 232.458 138.202 233.178 130.453C234.736 114.933 244.746 100.436 263.549 92.0084Z"
            fill="#452015"
          />
          <path
            d="M229.262 169.115C222.823 173.908 219.414 182.032 219.403 190.051C219.368 211.092 227.828 215.699 234.322 220.211C234.337 209.285 235.342 188.284 242.834 180.288C244.195 179.006 245.388 177.558 246.386 175.976C246.878 175.184 247.166 174.281 247.222 173.35C247.278 172.418 247.1 171.487 246.706 170.642C245.638 168.871 243.396 168.154 241.355 167.91C236.002 167.221 230.467 168.229 229.262 169.115Z"
            fill="#452015"
          />
          <path
            d="M231.01 127.528C228.491 137.69 231.543 150.744 237.673 159.087C244.123 167.915 255.209 171.613 265.681 174.576C302.836 185.081 341.169 192.387 379.875 192.367C391.169 192.364 402.619 191.711 413.476 188.407C424.333 185.103 434.608 178.945 441.231 169.594"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M244.595 235.218C285.032 257.192 330.561 268.082 376.564 266.781C387.123 266.492 397.715 265.506 407.748 262.188C417.782 258.87 427.258 253.036 433.513 244.443"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M270.23 326.817C295.108 333.987 320.412 341.218 346.354 340.965C372.296 340.712 399.484 331.941 416.419 311.95"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M252.648 96.0493C299.42 80.4789 358.238 80.3625 402.356 101.527"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M388.931 118.06C380.201 112.49 370.287 109.044 359.984 107.998C356.283 107.628 351.393 108.419 350.678 112.117C350.172 114.573 351.948 116.857 353.77 118.521C361.153 125.374 370.831 129.219 380.904 129.303C383.735 129.32 386.79 128.948 388.962 127.026C391.134 125.105 392.512 120.358 388.931 118.06Z"
            fill="#FFE8E0"
          />
          <path
            d="M331.131 99.6534C327.894 99.159 324.391 99.2265 321.539 100.847C319.808 101.846 318.297 103.802 318.872 105.705C319.339 107.256 320.971 108.071 322.448 108.693C327.819 110.87 333.652 112.287 339.438 111.644C340.92 111.548 342.358 111.11 343.642 110.364C344.281 109.98 344.811 109.44 345.184 108.795C345.557 108.149 345.759 107.42 345.772 106.675C345.677 104.218 334.367 100.148 331.131 99.6534Z"
            fill="#FFE8E0"
          />
          <path
            d="M68.5207 68.9673C113.969 26.3289 173.204 -3.78873 234.767 0.73983C252.685 2.06617 271.146 6.18947 284.895 17.6955C297.24 28.033 304.482 43.2299 310.2 58.3667C327.702 104.614 333.934 154.365 328.379 203.5C323.24 224.54 301.912 238.161 280.791 242.224C268.308 244.625 254.774 244.328 243.802 238.051C233.189 231.993 226.22 221.145 220.778 210.151C205.486 178.977 199.334 144.11 203.033 109.585C203.033 109.585 43.2208 92.708 68.5207 68.9673Z"
            fill="#A9563C"
          />
          <path
            d="M324.534 202.759C324.774 202.375 324.858 201.914 324.768 201.471C324.679 201.027 324.423 200.635 324.054 200.374C323.684 200.113 323.228 200.004 322.781 200.069C322.333 200.133 321.927 200.367 321.646 200.722C290.662 238.108 237.779 238.904 220.484 198.399C214.644 184.635 214.803 172.118 215.63 158.647C216.02 152.371 207.419 150.569 205.101 156.424C194.686 182.743 199.094 215.068 218.927 236.276C266.821 267.98 305.885 232.96 324.534 202.759Z"
            fill="#452015"
          />
          <path
            d="M209.786 126.948C211.937 115.02 216.698 103.715 223.73 93.8428C230.761 83.9704 239.888 75.7749 250.458 69.8432"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M224.784 92.6519C224.784 92.6519 220.686 87.63 219.756 75.5002"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M308.911 65.7398C308.911 65.7398 346.409 130.01 331.455 190.692"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
        </svg>
      </g>
      <g class="hand--open">
        <svg
          width="450"
          height="539"
          viewBox="0 0 450 539"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M386.555 467.878L382.89 467.206C398.081 462.894 411.874 453.744 419.044 439.824C426.105 426.095 425.349 404.124 405.046 399.33L402.316 398.678C402.381 398.565 402.4 398.414 402.43 398.282C418.627 395.428 432.804 382.932 438.562 367.319C440.668 361.657 441.656 355.639 441.471 349.6C441.236 343.119 439.181 336.834 435.542 331.466C431.903 326.098 426.826 321.862 420.892 319.244C422.621 318.883 424.312 318.364 425.945 317.692C437.289 312.983 444.193 300.746 445.964 288.559C447.735 276.371 451.566 255.455 446.557 244.272C434.144 216.551 413.123 186.46 325.486 187.073C223.594 187.794 230.793 197.805 230.177 191.352C255.251 150.922 340.18 79.6822 323.802 29.1887C307.496 -20.7674 239.576 4.31285 217.67 37.4954C194.753 72.2078 141.991 132.435 113.877 163.05C81.9514 197.827 13.8165 223.06 2.62619 284.022C2.32274 286.987 1.78707 290.015 1.41291 293.263C-3.69844 337.516 25.4598 379.893 33.8217 409.27C42.1836 438.646 24.8989 444.844 66.4906 502.806L66.746 503.103C83.2989 513.053 102.666 518.068 121.694 522.391C162.75 531.719 204.598 540.396 246.73 537.642C261.364 536.713 275.852 534.186 289.936 530.106C295.963 530.987 302.019 531.678 308.106 532.075C327.886 533.356 348.219 531.458 366.591 523.717C377.346 519.188 387.669 512.308 393.413 502.079C399.157 491.851 399.141 477.858 386.555 467.878Z"
            fill="#A9563C"
          />
          <path
            d="M366.989 222.767C375.05 213.997 385.734 208.073 397.442 205.881"
            stroke="#452015"
            stroke-width="3.02"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M236.877 443.908C224.018 438.181 215.921 423.264 218.044 409.167C211.386 414.35 206.022 421.009 202.376 428.618C198.73 436.228 196.901 444.58 197.034 453.017C197.474 469.795 206.406 486.025 221.098 493.918L222.483 494.579C217.84 486.761 218.276 476.666 221.472 468.05C224.668 459.434 230.296 451.967 236.372 445.002C236.298 444.971 236.199 444.983 236.877 443.908Z"
            fill="#452015"
          />
          <path
            d="M198.503 257.554C194.677 322.18 149.004 382.748 88.081 404.05"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M217 474.555C212.279 490.732 230.79 510.23 245.26 518.468C259.729 526.707 276.644 529.349 293.207 531.301C307.936 533.05 323.011 535.38 337.577 532.45C355.905 528.806 376.043 520.665 387.005 510.458"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M254.119 303.983C249.195 306.846 244.885 310.657 241.441 315.195C237.997 319.732 235.485 324.907 234.052 330.421C232.677 335.922 232.415 341.642 233.283 347.245C234.151 352.848 236.13 358.222 239.105 363.049"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M237.145 378.383C231.667 382.071 227.154 387.021 223.987 392.817C220.82 398.612 219.091 405.083 218.946 411.685C218.912 418.239 220.483 424.701 223.52 430.508C226.558 436.316 230.97 441.292 236.372 445.002"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M36.6637 429.547C41.3068 476.245 57.3816 505.283 98.3266 516.544"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M263.995 225.299C254.825 225.595 245.851 228.032 237.791 232.415C229.731 236.798 222.808 243.005 217.575 250.54C211.819 258.949 208.275 269.108 208.854 279.246C209.432 289.383 214.424 299.378 222.859 304.848C231.293 310.319 243.14 310.6 251.341 304.596C245.193 299.825 240.347 293.582 237.249 286.443C234.152 279.305 232.903 271.501 233.618 263.752C235.182 248.224 245.192 233.726 263.995 225.299Z"
            fill="#452015"
          />
          <path
            d="M229.703 302.414C223.269 307.198 219.869 315.327 219.849 323.341C219.814 344.382 228.273 348.989 234.724 353.476C234.74 342.55 235.74 321.557 243.231 313.562C244.595 312.282 245.791 310.835 246.792 309.255C247.284 308.46 247.57 307.555 247.625 306.621C247.681 305.687 247.503 304.755 247.108 303.907C246.066 302.093 243.807 301.424 241.752 301.184C236.413 300.492 230.912 301.519 229.703 302.414Z"
            fill="#452015"
          />
          <path
            d="M231.456 260.818C228.937 270.98 231.997 284.04 238.118 292.377C244.569 301.205 255.655 304.904 266.126 307.866C303.268 318.375 341.606 325.672 380.306 325.66C391.615 325.654 403.081 324.953 413.913 321.692C424.745 318.431 435.082 312.206 441.677 302.884"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M245.036 368.517C285.474 390.49 331.005 401.376 377.009 400.071C387.569 399.782 398.161 398.796 408.189 395.487C418.217 392.177 427.712 386.332 433.967 377.738"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M270.679 460.121C295.548 467.286 320.853 474.517 346.804 474.269C372.754 474.021 399.942 465.25 416.882 445.25"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M253.089 229.348C299.86 213.778 358.683 213.653 402.797 234.826"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M389.377 251.35C380.649 245.779 370.735 242.337 360.433 241.302C356.724 240.927 351.847 241.714 351.084 245.396C350.578 247.852 352.359 250.127 354.176 251.8C361.559 258.652 371.237 262.498 381.31 262.582C384.154 262.595 387.201 262.218 389.368 260.305C391.535 258.392 392.966 253.653 389.377 251.35Z"
            fill="#FFE8E0"
          />
          <path
            d="M331.576 232.944C328.34 232.449 324.837 232.517 321.988 234.151C320.262 235.141 318.746 237.106 319.313 239.004C319.78 240.555 321.411 241.37 322.893 241.983C328.273 244.165 334.092 245.585 339.884 244.934C341.367 244.845 342.807 244.407 344.087 243.654C344.724 243.268 345.254 242.728 345.626 242.083C345.998 241.438 346.202 240.709 346.217 239.965C346.118 237.517 334.821 233.443 331.576 232.944Z"
            fill="#FFE8E0"
          />
        </svg>
      </g>
    </svg>
    <svg
      v-show="props.isLeft == false"
      :class="{
        hand: true,
        ['is-color-' + props.color]: true,
        'is-open': props.isOpen,
      }"
      style="width: 150px; height: 200x"
      viewBox="0 0 500 550"
      preserveAspectRatio="xMidYMin meet"
    >
      <g class="hand--closed">
        <svg
          width="450"
          height="406"
          viewBox="0 0 450 406"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M63.2639 71.2808L66.9336 71.9446C51.7423 76.2566 37.9497 85.4061 30.7829 99.3394C23.7224 113.069 24.4693 135.035 44.7811 139.834L47.5071 140.472C47.4371 140.594 47.4327 140.741 47.3887 140.877C31.1968 143.723 17.0147 156.227 11.2655 171.845C9.15911 177.507 8.17106 183.525 8.3561 189.564C8.59128 196.045 10.646 202.329 14.2853 207.698C17.9245 213.066 23.0013 217.302 28.9349 219.92C27.2076 220.285 25.5165 220.804 23.8822 221.472C12.543 226.172 5.64795 238.414 3.86336 250.605C2.07878 262.796 -1.6895 283.703 3.32147 294.864C15.7144 322.619 36.7547 352.676 124.398 352.054C197.128 351.541 273.789 364 311.764 371.145C318.578 372.402 325.577 372.242 332.326 370.675C339.075 369.109 345.43 366.169 350.993 362.04C385.599 333.58 437.587 307.534 447.208 255.03C447.511 252.065 448.062 249.011 448.436 245.763C453.547 201.51 424.438 159.069 416.027 129.757C407.617 100.444 424.935 94.208 383.343 36.2468L383.088 35.9492C367.266 26.4335 348.87 21.436 330.664 17.2295C329.83 17.0943 328.992 16.9455 328.135 16.7506C287.078 7.42264 245.239 -1.24895 203.094 1.508C188.459 2.42968 173.97 4.95696 159.887 9.0443C153.865 8.1542 147.804 7.47231 141.717 7.07557C121.938 5.79428 101.61 7.68338 83.238 15.4245C72.5258 19.9786 62.1681 26.8388 56.4156 37.0622C50.6632 47.2857 50.6677 61.3179 63.2639 71.2808Z"
            fill="#A9563C"
          />
          <path
            d="M82.863 316.354C74.795 325.139 64.0996 331.077 52.3766 333.278"
            stroke="#452015"
            stroke-width="3.02"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M212.947 95.2424C225.801 100.978 233.897 115.895 231.788 129.988C238.446 124.804 243.808 118.144 247.453 110.534C251.097 102.923 252.924 94.5702 252.789 86.1332C252.358 69.3604 243.421 53.1389 228.721 45.2406L227.341 44.5708C231.984 52.3897 231.543 62.4929 228.355 71.1141C225.168 79.7353 219.523 87.192 213.451 94.1482C213.521 94.1882 213.612 94.2295 212.947 95.2424Z"
            fill="#452015"
          />
          <path
            d="M251.321 281.596C255.146 216.971 300.828 156.408 361.743 135.1"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M232.827 64.6085C237.491 48.4103 219.028 28.9289 204.568 20.6955C190.107 12.4621 173.169 9.8191 156.612 7.85755C141.886 6.12246 126.812 3.79292 112.242 6.70863C93.914 10.3531 73.7753 18.4944 62.8134 28.7009"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M195.739 235.187C200.664 232.324 204.973 228.513 208.417 223.976C211.861 219.438 214.373 214.263 215.806 208.749C217.182 203.249 217.443 197.528 216.575 191.925C215.707 186.322 213.728 180.949 210.753 176.121"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M212.674 160.776C218.155 157.088 222.669 152.137 225.837 146.34C229.005 140.543 230.734 134.07 230.878 127.465C230.912 120.911 229.342 114.449 226.304 108.641C223.267 102.834 218.854 97.8581 213.451 94.1482"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M413.16 109.603C408.52 62.9187 392.451 33.8724 351.492 22.6148"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M185.829 313.852C194.999 313.555 203.972 311.118 212.032 306.736C220.092 302.353 227.016 296.146 232.249 288.61C238.013 280.206 241.544 270.051 240.974 259.918C240.404 249.786 235.395 239.781 226.96 234.311C218.525 228.84 206.683 228.551 198.478 234.563C204.629 239.331 209.478 245.573 212.576 252.713C215.674 259.852 216.92 267.658 216.2 275.407C214.642 290.927 204.632 305.424 185.829 313.852Z"
            fill="#452015"
          />
          <path
            d="M220.116 236.745C226.555 231.952 229.964 223.828 229.975 215.809C230.01 194.768 221.55 190.161 215.056 185.649C215.041 196.575 214.036 217.576 206.544 225.572C205.183 226.853 203.99 228.302 202.992 229.884C202.5 230.676 202.212 231.579 202.156 232.51C202.1 233.442 202.278 234.373 202.672 235.218C203.74 236.989 205.982 237.706 208.023 237.95C213.376 238.639 218.911 237.631 220.116 236.745Z"
            fill="#452015"
          />
          <path
            d="M218.368 278.332C220.886 268.17 217.835 255.116 211.705 246.773C205.255 237.945 194.169 234.247 183.697 231.284C146.542 220.779 108.209 213.473 69.5034 213.493C58.2087 213.496 46.7585 214.149 35.9018 217.453C25.0451 220.757 14.7698 226.915 8.14685 236.266"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M204.783 170.642C164.346 148.668 118.817 137.778 72.8141 139.079C62.2549 139.368 51.6628 140.354 41.6296 143.672C31.5965 146.99 22.1202 152.824 15.8652 161.417"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M179.148 79.0429C154.27 71.8732 128.965 64.6421 103.023 64.895C77.0815 65.1479 49.8934 73.919 32.9591 93.9101"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M196.73 309.811C149.959 325.381 91.1401 325.497 47.0218 304.333"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M60.4467 287.8C69.1769 293.37 79.0912 296.816 89.394 297.862C93.0948 298.232 97.9854 297.441 98.7001 293.743C99.206 291.287 97.43 289.003 95.6079 287.339C88.2245 280.486 78.5465 276.641 68.4735 276.557C65.643 276.54 62.5876 276.912 60.4159 278.834C58.2441 280.755 56.866 285.502 60.4467 287.8Z"
            fill="#FFE8E0"
          />
          <path
            d="M118.247 306.207C121.484 306.701 124.987 306.633 127.839 305.013C129.57 304.014 131.081 302.058 130.505 300.155C130.039 298.604 128.407 297.789 126.93 297.167C121.559 294.99 115.726 293.573 109.939 294.216C108.458 294.312 107.019 294.75 105.736 295.496C105.097 295.88 104.567 296.42 104.194 297.065C103.821 297.711 103.619 298.44 103.606 299.185C103.701 301.642 115.011 305.712 118.247 306.207Z"
            fill="#FFE8E0"
          />
          <path
            d="M380.857 336.893C335.409 379.531 276.174 409.649 214.611 405.12C196.693 403.794 178.232 399.671 164.483 388.165C152.138 377.827 144.896 362.63 139.178 347.493C121.676 301.246 115.444 251.495 120.999 202.36C126.138 181.32 147.465 167.699 168.587 163.636C181.07 161.235 194.604 161.532 205.576 167.809C216.189 173.867 223.158 184.715 228.6 195.709C243.892 226.883 250.044 261.75 246.345 296.275C246.345 296.275 406.157 313.152 380.857 336.893Z"
            fill="#A9563C"
          />
          <path
            d="M124.844 203.101C124.604 203.485 124.52 203.946 124.61 204.389C124.699 204.833 124.955 205.225 125.325 205.486C125.694 205.747 126.15 205.856 126.597 205.791C127.045 205.727 127.451 205.493 127.732 205.138C158.717 167.752 211.599 166.956 228.894 207.461C234.735 221.225 234.575 233.742 233.748 247.213C233.358 253.489 241.959 255.291 244.278 249.436C254.692 223.117 250.284 190.792 230.451 169.584C182.557 137.88 143.493 172.9 124.844 203.101Z"
            fill="#452015"
          />
          <path
            d="M239.592 278.912C237.441 290.84 232.68 302.145 225.648 312.017C218.617 321.89 209.49 330.085 198.92 336.017"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M224.594 313.208C224.594 313.208 228.692 318.23 229.622 330.36"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M140.467 340.12C140.467 340.12 102.969 275.85 117.923 215.168"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
        </svg>
      </g>
      <g class="hand--open">
        <svg
          width="449"
          height="539"
          viewBox="0 0 449 539"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M62.4836 71.1918L66.1483 71.8642C50.9571 76.1763 37.1644 85.3258 29.994 99.2454C22.9335 112.975 23.689 134.946 43.9922 139.74L46.7218 140.392C46.6568 140.505 46.6388 140.656 46.6084 140.788C30.4115 143.642 16.2344 156.138 10.4766 171.751C8.37015 177.413 7.3821 183.431 7.56715 189.47C7.80233 195.951 9.85707 202.235 13.4963 207.604C17.1356 212.972 22.2124 217.208 28.146 219.826C26.4176 220.187 24.7263 220.706 23.0933 221.378C11.7491 226.086 4.84533 238.324 3.07441 250.511C1.30348 262.699 -2.52809 283.615 2.48154 294.798C14.8944 322.519 35.9148 352.61 123.553 351.997C225.444 351.276 218.245 341.265 218.861 347.718C193.787 388.147 108.859 459.388 125.236 509.881C141.543 559.837 209.462 534.757 231.368 501.575C254.285 466.862 307.048 406.635 335.161 376.02C367.087 341.243 435.222 316.01 446.412 255.048C446.716 252.082 447.251 249.055 447.625 245.807C452.737 201.553 423.578 159.177 415.217 129.8C406.855 100.424 424.139 94.2256 382.548 36.2644L382.292 35.9668C365.739 26.0174 346.372 21.002 327.345 16.6789C286.288 7.35096 244.44 -1.32564 202.309 1.42766C187.675 2.35693 173.186 4.88406 159.102 8.96396C153.075 8.08251 147.019 7.39196 140.932 6.99523C121.153 5.71393 100.82 7.6117 82.4477 15.3528C71.6922 19.8819 61.3691 26.7621 55.6253 36.9906C49.8816 47.219 49.8974 61.2116 62.4836 71.1918Z"
            fill="#A9563C"
          />
          <path
            d="M82.0491 316.303C73.9881 325.073 63.3044 330.997 51.5963 333.189"
            stroke="#452015"
            stroke-width="3.02"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M212.161 95.1621C225.021 100.889 233.117 115.806 230.994 129.903C237.653 124.72 243.016 118.061 246.662 110.452C250.308 102.842 252.137 94.4898 252.004 86.053C251.564 69.2751 242.632 53.045 227.941 45.1516L226.556 44.4906C231.198 52.3094 230.763 62.404 227.566 71.0202C224.37 79.6363 218.743 87.1031 212.666 94.0679C212.74 94.0993 212.84 94.0873 212.161 95.1621Z"
            fill="#452015"
          />
          <path
            d="M250.536 281.516C254.361 216.89 300.034 156.322 360.957 135.02"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M232.038 64.5145C236.759 48.3376 218.248 28.8399 203.779 20.6015C189.309 12.3631 172.394 9.72144 155.831 7.76855C141.102 6.0198 126.028 3.69026 111.461 6.61963C93.1338 10.2641 72.995 18.4054 62.0332 28.6119"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M194.919 235.087C199.844 232.224 204.153 228.413 207.597 223.875C211.041 219.338 213.553 214.163 214.986 208.649C216.362 203.148 216.623 197.428 215.755 191.825C214.888 186.221 212.908 180.848 209.933 176.021"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M211.893 160.687C217.372 156.998 221.884 152.049 225.051 146.253C228.218 140.458 229.947 133.987 230.092 127.385C230.126 120.831 228.555 114.369 225.518 108.562C222.48 102.754 218.068 97.7784 212.666 94.0679"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M412.375 109.523C407.732 62.8248 391.657 33.7871 350.712 22.5259"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M185.044 313.771C194.214 313.475 203.187 311.038 211.247 306.655C219.307 302.272 226.23 296.065 231.464 288.53C237.219 280.121 240.764 269.962 240.185 259.824C239.606 249.687 234.615 239.692 226.18 234.222C217.745 228.751 205.898 228.47 197.697 234.474C203.845 239.245 208.692 245.488 211.789 252.627C214.887 259.765 216.136 267.569 215.42 275.318C213.856 290.846 203.847 305.344 185.044 313.771Z"
            fill="#452015"
          />
          <path
            d="M219.336 236.656C225.77 231.872 229.17 223.743 229.189 215.729C229.225 194.688 220.765 190.08 214.314 185.594C214.299 196.52 213.299 217.512 205.807 225.508C204.443 226.787 203.247 228.235 202.247 229.815C201.755 230.61 201.468 231.515 201.413 232.449C201.358 233.383 201.535 234.315 201.93 235.163C202.973 236.977 205.231 237.646 207.286 237.886C212.626 238.578 218.126 237.551 219.336 236.656Z"
            fill="#452015"
          />
          <path
            d="M217.583 278.252C220.101 268.09 217.041 255.03 210.92 246.692C204.47 237.865 193.384 234.166 182.912 231.203C145.77 220.695 107.432 213.398 68.7318 213.409C57.4235 213.416 45.9569 214.117 35.1252 217.378C24.2935 220.639 13.956 226.864 7.36164 236.186"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M204.003 170.553C163.564 148.58 118.033 137.694 72.0289 138.999C61.4697 139.288 50.8776 140.274 40.8494 143.583C30.8213 146.893 21.3263 152.738 15.0714 161.332"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M178.359 78.9489C153.49 71.7843 128.185 64.5532 102.235 64.8011C76.2839 65.0489 49.0959 73.82 32.1565 93.8198"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M195.95 309.722C149.178 325.292 90.3549 325.417 46.2415 304.244"
            stroke="#452015"
            stroke-width="4.03"
            stroke-miterlimit="10"
            stroke-linecap="round"
          />
          <path
            d="M59.6614 287.72C68.3887 293.291 78.3032 296.733 88.6051 297.768C92.3146 298.143 97.1915 297.356 97.9545 293.674C98.4604 291.218 96.6794 288.943 94.8623 287.27C87.4789 280.417 77.8009 276.572 67.7278 276.488C64.8838 276.475 61.837 276.852 59.6703 278.765C57.5035 280.677 56.0721 285.417 59.6614 287.72Z"
            fill="#FFE8E0"
          />
          <path
            d="M117.462 306.126C120.698 306.621 124.202 306.553 127.05 304.919C128.776 303.929 130.292 301.964 129.725 300.066C129.258 298.515 127.627 297.7 126.145 297.087C120.765 294.905 114.946 293.484 109.154 294.136C107.672 294.225 106.231 294.663 104.951 295.416C104.314 295.802 103.785 296.342 103.412 296.987C103.04 297.632 102.837 298.361 102.821 299.105C102.921 301.553 114.217 305.627 117.462 306.126Z"
            fill="#FFE8E0"
          />
        </svg>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      required: true,
    },
    isOpen: Boolean,
    isLeft: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
