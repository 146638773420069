const {
  id,
  language,
  name,
  config,
  translations,
  main_questions,
  followup_interfaces,
  recovery_questions,
  interrupt_questions,
  intro_survey,
  demographic_survey,
  levels,
  share: share_config,
  surveyLanguage,
} = window.SURVEY_CONFIG || {};

let languages = window.APP_LANGUAGES || {};

export {
  id,
  language,
  name,
  config,
  share_config,
  translations,
  main_questions,
  followup_interfaces,
  recovery_questions,
  interrupt_questions,
  intro_survey,
  demographic_survey,
  levels,
  languages,
  surveyLanguage,
};
