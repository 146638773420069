<template>
  <BaseScreen :name="id" class="demosurvey">
    <div class="demosurvey__header">
      <p>{{ progress_heading }}</p>
    </div>
    <div
      ref="demoSurveyBody"
      tabindex="0"
      aria-live="assertive"
      class="demosurvey__body"
    >
      <h3 class="demosurvey__title">{{ title }}</h3>
      <p v-if="multiple_choice">{{ multipleChoice }}</p>
      <div
        :class="{
          demosurvey__buttons: true,
          'has-layout-grid': answers.length <= 8,
          'has-layout-fluid': answers.length > 8,
        }"
      >
        <BaseButton
          v-for="answer in answers"
          :key="answer.id + answer.name"
          :class="{ 'is-selected': hasAnswer(answer.name) }"
          @click="toggleAnswer(answer.name)"
          >{{ answer.text }}</BaseButton
        >
      </div>
    </div>
    <div class="demosurvey__footer">
      <BaseButton
        data-cy="submitDemo"
        is-primary
        :disabled="selected.length === 0"
        @click="logAnswer()"
        >{{ submit }}</BaseButton
      >

      <BaseButton is-link @click="logAnswer('abstain')">{{
        wontDisclose
      }}</BaseButton>
    </div>
  </BaseScreen>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
    multiple_choice: Boolean,
    heading: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    ...mapState({
      submit: state => state.survey.translations.submit,
      wontDisclose: state => state.survey.translations['wont-disclose'],
      multipleChoice: state => state.survey.translations['multiple-choice'],
    }),
    progress_heading() {
      return this.heading
        .replace('%current%', this.$l10n(this.index + 1))
        .replace('%total%', this.$l10n(this.total));
    },
  },
  methods: {
    toggleAnswer(answer) {
      const selected = [...this.selected];
      const index = selected.indexOf(answer);

      // Remove answer if already selected
      if (index >= 0) {
        selected.splice(index, 1);
        this.selected = selected;
        return;
      }

      // Otherwise, add/replace depending on multiple_choice
      if (this.multiple_choice) {
        this.selected.push(answer);
      } else {
        this.selected = [answer];
      }
    },
    hasAnswer(answer) {
      return this.selected.includes(answer);
    },
    logAnswer(answer) {
      answer = answer || this.selected;

      if (!(answer instanceof Array)) {
        answer = [answer];
      }

      this.$log.demographic({
        question_name: this.name,
        answers: answer,
      });

      this.$emit('next');
    },
  },
};
</script>
