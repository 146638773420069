<template functional>
  <!-- carry over any attributes, expecially classes -->
  <div v-bind="data.attrs" :class="['star', data.class, data.staticClass]">
    <!-- <BaseIcon type="starLarge" class="star__icon" /> -->
    <svg
      preserveAspectRatio="none"
      viewBox="0 0 365 381"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_0_1)">
        <path
          d="M297.51 248.32C296.319 241.372 296.835 234.239 299.015 227.536C301.194 220.832 304.971 214.759 310.02 209.84L349.78 171.08C355.065 165.986 358.755 159.464 360.4 152.31C361.868 145.601 361.523 138.623 359.4 132.092C357.277 125.56 353.453 119.713 348.32 115.15C342.79 110.306 335.969 107.179 328.69 106.15L273.75 98.15C266.773 97.1397 260.147 94.4468 254.443 90.3039C248.739 86.161 244.129 80.6924 241.01 74.37L216.44 24.58C213.226 17.9749 208.16 12.4471 201.86 8.67C195.933 5.20526 189.191 3.37927 182.325 3.37927C175.459 3.37927 168.717 5.20526 162.79 8.67C156.477 12.4616 151.407 18.0114 148.2 24.64L123.63 74.43C120.512 80.7511 115.904 86.2189 110.202 90.3618C104.499 94.5046 97.8752 97.1981 90.9 98.21L35.95 106.21C28.9063 107.178 22.2818 110.125 16.8458 114.708C11.4098 119.291 7.38543 125.321 5.23998 132.1C3.10106 138.629 2.75569 145.612 4.23998 152.32C5.88444 159.477 9.57829 165.999 14.87 171.09L54.63 209.85C59.6756 214.771 63.4497 220.845 65.6272 227.548C67.8047 234.251 68.3205 241.383 67.13 248.33L57.74 303.06C56.4643 310.287 57.319 317.73 60.2 324.48C62.2571 329.323 65.299 333.686 69.1325 337.291C72.966 340.896 77.5072 343.664 82.4678 345.419C87.4284 347.175 92.6999 347.88 97.9475 347.489C103.195 347.099 108.304 345.621 112.95 343.15L162.09 317.31C168.331 314.032 175.275 312.32 182.325 312.32C189.375 312.32 196.319 314.032 202.56 317.31L251.7 343.15C257.981 346.471 265.068 347.964 272.155 347.461C279.242 346.957 286.047 344.476 291.795 340.3C297.544 336.124 302.006 330.42 304.676 323.836C307.346 317.251 308.117 310.05 306.9 303.05L297.51 248.32Z"
          fill="#3EC3EE"
        />
        <path
          d="M297.51 248.32C296.319 241.372 296.835 234.239 299.015 227.536C301.194 220.832 304.971 214.759 310.02 209.84L349.78 171.08C355.065 165.986 358.755 159.464 360.4 152.31C361.868 145.601 361.523 138.623 359.4 132.092C357.277 125.56 353.453 119.713 348.32 115.15C342.79 110.306 335.969 107.179 328.69 106.15L273.75 98.15C266.773 97.1397 260.147 94.4468 254.443 90.3039C248.739 86.161 244.129 80.6924 241.01 74.37L216.44 24.58C213.226 17.9749 208.16 12.4471 201.86 8.67C195.933 5.20526 189.191 3.37927 182.325 3.37927C175.459 3.37927 168.717 5.20526 162.79 8.67C156.477 12.4616 151.407 18.0114 148.2 24.64L123.63 74.43C120.512 80.7511 115.904 86.2189 110.202 90.3618C104.499 94.5046 97.8752 97.1981 90.9 98.21L35.95 106.21C28.9063 107.178 22.2818 110.125 16.8458 114.708C11.4098 119.291 7.38543 125.321 5.23998 132.1C3.10106 138.629 2.75569 145.612 4.23998 152.32C5.88444 159.477 9.57829 165.999 14.87 171.09L54.63 209.85C59.6756 214.771 63.4497 220.845 65.6272 227.548C67.8047 234.251 68.3205 241.383 67.13 248.33L57.74 303.06C56.4643 310.287 57.319 317.73 60.2 324.48C62.2571 329.323 65.299 333.686 69.1325 337.291C72.966 340.896 77.5072 343.664 82.4678 345.419C87.4284 347.175 92.6999 347.88 97.9475 347.489C103.195 347.099 108.304 345.621 112.95 343.15L162.09 317.31C168.331 314.032 175.275 312.32 182.325 312.32C189.375 312.32 196.319 314.032 202.56 317.31L251.7 343.15C257.981 346.471 265.068 347.964 272.155 347.461C279.242 346.957 286.047 344.476 291.795 340.3C297.544 336.124 302.006 330.42 304.676 323.836C307.346 317.251 308.117 310.05 306.9 303.05L297.51 248.32Z"
          stroke="#452015"
          stroke-width="6"
        />
      </g>
      <path
        d="M168.118 41.9934C171.942 32.5188 172.359 23.755 169.049 22.419C165.739 21.0831 159.956 27.6807 156.132 37.1553C152.307 46.63 151.891 55.3937 155.201 56.7297C158.511 58.0657 164.294 51.468 168.118 41.9934Z"
        fill="#FCE4DB"
      />
      <path
        d="M155.465 67.5006C156.145 64.1713 155.301 61.1873 153.58 60.8356C151.859 60.4838 149.912 62.8976 149.232 66.2269C148.552 69.5561 149.395 72.5401 151.116 72.8919C152.838 73.2436 154.784 70.8299 155.465 67.5006Z"
        fill="#FCE4DB"
      />
      <defs>
        <filter
          id="filter0_d_0_1"
          x="0.334473"
          y="0.379272"
          width="363.96"
          height="380.215"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="30" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.270588 0 0 0 0 0.12549 0 0 0 0 0.0823529 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_0_1"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_0_1"
            result="shape"
          />
        </filter>
      </defs>
    </svg>

    <div v-if="props.number" class="star__label">
      {{ parent.$l10n(props.number) }}
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      default: 0,
    },
  },
};
</script>
