import { injectGlobal } from 'vue-styled-components';
import languages from '../framework/compiler/languages.json';

const fonts = language => {
  const langObj = languages.find(el => el.slug === language);
  const { font, headerFont } = langObj;

  return injectGlobal`
        p {
            font-family: ${font} !important;
        }
        body {
            font-family: ${font} !important;
        }
        .button.is-primary {
            font-family: ${headerFont} !important;
        }
        .button {
            font-family: ${font} !important;
        }
        .is-padded {
            font-family: ${font} !important;
        }
        .frame {
            font-family: ${headerFont} !important;
        }
        .homescreen__tagline {
            font-family: ${font} !important;
        }
        .homescreen__header {
            font-family: ${headerFont} !important;
        }
        .star__label {
            font-family: ${headerFont} !important;
        }
        h2 {
            font-family: ${headerFont} !important;
        }


    `;
};

export default fonts;
