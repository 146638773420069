// a short haiku outlining the fact that this was indeed intentional:
// a word to the wise
// the things in this file defile
// basic decency
import { injectGlobal } from 'vue-styled-components';
import get from 'better-theme-get';
import theme from './theme';

// with this injectGlobal function, we cannot access the theme automagically, so we leverage better theme gets hoc method to statically get the items
const tget = s =>
  get(s)({
    theme,
  });

const global = injectGlobal`

    body {
        overflow: hidden;
        font-size: 1.4rem;
        font-weight: 400;
        line-height: 1.3;
        
        ${tget('mediaQueries.desktopHeight')} {
            font-size: 1.8rem;
        }
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        line-height: 1.2;
        margin: 0 0 0.5em;
    }
    
    p {
        margin: 0 0 1em;
    }
    
    a {
        display: inline-block;
        line-height: 1.2;
        color: inherit;
        //text-decoration: none;
    
        background-image: linear-gradient(
        to right,
        rgba($tertiary, 0) 0,
        rgba($tertiary, 0) 50%,
        rgba($tertiary, 1) 50%,
        rgba($tertiary, 1) 100%
        );
        background-position: right bottom;
        background-repeat: repeat-x;
        background-size: 4px 2px;
    
        &:hover {
        background-size: 200% 2px;
        }
    
        &:focus {
        color: $color-white;
        background: $tertiary;
        }
    
        &[data-with-image] {
        display: inline-block;
        background: none;
        }
    }  

`;

export default global;
