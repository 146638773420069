const fontSizes = [12, 14, 16, 24, 32, 48, 64, 96, 128];
const space = [
  '0rem', // 0px
  '0.5rem', // 8px
  '1rem', // 16px
  '1.5rem', // 24px
  '2rem', // 32px
  '2.5rem', // 40px
  '3rem', // 48px
  '3.5rem', // 56px
  '4rem', // 64px
  '4.5rem', // 72px
  '5rem', // 80px
  '5.5rem', // 88px
  '6rem', // 96px
];

const lightBlue = '#62e0ec';
const darkBlue = '#063f98';
const lightYellow = '#FFE381';
const medYellow = '#ffdd83';
const black = '#1e4376';
const transparentBlue = 'rgb(48 77 99 / 78%)';
const lightGreen = '#52E798';
const SIYellow = '#ffd132';

const colors = {
  background: SIYellow,
  buttonBackground: medYellow,
  modalBackground: transparentBlue,
  buttonText: black,
  buttonBorder: medYellow,
  buttonHover: lightGreen,
  text: darkBlue,
  // main screen thumbs
  thumbDownBgColor: lightBlue,
  thumbDownStrokeColor: darkBlue,
  thumbDownFaceColor: darkBlue,
  modalBg: lightYellow,
  thumbUpBgColor: lightBlue,
  thumbUpStrokeColor: darkBlue,
  thumbUpFaceColor: darkBlue,
  // in game thumbs
  trueThumbBgColor: lightBlue,
  trueThumbStrokeColor: darkBlue,
  falseThumbBgColor: lightBlue,
  falseThumbStrokeColor: darkBlue,
  progressStarStrokeColor: '#4e1f7d',
};
const breakpoints = ['800px'];
breakpoints.iphone5MaxHeight = '480px';
breakpoints.iphone6MaxHeight = '560px';
breakpoints.mobileMaxHeight = '600px';
breakpoints.mobileMaxWidth = '320px';
breakpoints.tabletMinHeight = '800px';
breakpoints.desktopMinWidth = '800px';
breakpoints.desktopMinHeight = '1000px';

const mediaQueries = {
  iphone5Height: `@media screen and (max-height: ${breakpoints.iphone5MaxHeight})`,
  mobile: `@media screen and (max-width: 768px)`,
  desktopWidth: `@media screen and (min-width: ${breakpoints[0]})`,
  desktopHeight: `@media screen and (min-height: 1000px)`,
  largeDesktop: `@media screen and (min-width: ${breakpoints[1]})`,
};

// these are defaults for various preferences, usually a show/hide
const prefs = {
  // usually, the spike protein icons
  showBackgroundElements: false,
  showHomescreenCloud: false,
};

const animations = {
  easeInQuad: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
  easeInCubic: 'cubic-bezier(0.55, 0.055, 0.675, 0.19)',
  easeInQuart: 'cubic-bezier(0.895, 0.03, 0.685, 0.22)',
  easeInQuint: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
  easeInSine: 'cubic-bezier(0.47, 0, 0.745, 0.715)',
  easeInExpo: 'cubic-bezier(0.95, 0.05, 0.795, 0.035)',
  easeInCirc: 'cubic-bezier(0.6, 0.04, 0.98, 0.335)',
  easeInBack: 'cubic-bezier(0.6, -0.28, 0.735, 0.045)',

  easeOutQuad: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  easeOutCubic: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
  easeOutQuart: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
  easeOutQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
  easeOutSine: 'cubic-bezier(0.39, 0.575, 0.565, 1)',
  easeOutExpo: 'cubic-bezier(0.19, 1, 0.22, 1)',
  easeOutCirc: 'cubic-bezier(0.075, 0.82, 0.165, 1)',
  easeOutBack: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)',

  easeInOutQuad: 'cubic-bezier(0.455, 0.03, 0.515, 0.955)',
  easeInOutCubic: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  easeInOutQuart: 'cubic-bezier(0.77, 0, 0.175, 1)',
  easeInOutQuint: 'cubic-bezier(0.86, 0, 0.07, 1)',
  easeInOutSine: 'cubic-bezier(0.445, 0.05, 0.55, 0.95)',
  easeInOutExpo: 'cubic-bezier(1, 0, 0, 1)',
  easeInOutCirc: 'cubic-bezier(0.785, 0.135, 0.15, 0.86)',
  easeInOutBack: 'cubic-bezier(0.68, -0.55, 0.265, 1.55)',
};

const theme = {
  fontSizes,
  space,
  colors,
  breakpoints,
  mediaQueries,
  animations,
  prefs,
};

exports['default'] = theme;
module.exports = exports['default'];
