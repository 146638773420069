<template>
  <div class="option-question">
    <div class="option-question__buttons">
      <BaseButton
        v-for="option in options"
        :key="option.value"
        is-primary
        :color="option.color"
        @click="$emit('answer', option.value)"
        >{{ option.label }}</BaseButton
      >
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  inheritAttrs: false,
  props: {
    values: {
      type: Array,
      required: true,
    },
    labels: {
      type: Array,
      required: true,
    },
    colors: {
      type: Array,
      default: () => {
        return [];
      },
      // required: true,
    },
    timer: {
      type: Number,
      default: 0,
    },
  },
  data() {
    const options = this.createLabels();
    return { options };
  },
  computed: {
    ...mapState(['language']),
  },
  watch: {
    language() {
      this.options = this.createLabels();
    },
  },
  methods: {
    createLabels() {
      let colors;
      if (this.colors === null || this.colors.length > 1) {
        colors = ['red', 'green'];
      } else colors = this.colors;
      const options = [];
      const optionCount = Math.min(this.values?.length, this.labels?.length);

      for (let i = 0; i < optionCount; i++) {
        options.push({
          color: colors[i],
          value: this.values[i],
          label: this.labels[i],
        });
      }
      return options;
    },
  },
};
</script>
