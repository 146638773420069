<template functional>
  <svg :class="['emote', 'emote--' + props.face]" viewBox="0 0 76 76">
    <g v-if="props.face === 'very-upset'" class="emote__face">
      <svg
        preserveAspectRatio="none"
        viewBox="0 0 156 166"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_8_30479)">
          <path
            d="M78 152C118.869 152 152 118.869 152 78C152 37.1309 118.869 4 78 4C37.1309 4 4 37.1309 4 78C4 118.869 37.1309 152 78 152Z"
            fill="#FFA826"
          />
          <path
            d="M78 152C118.869 152 152 118.869 152 78C152 37.1309 118.869 4 78 4C37.1309 4 4 37.1309 4 78C4 118.869 37.1309 152 78 152Z"
            stroke="#452015"
            stroke-width="8"
            stroke-miterlimit="10"
          />
        </g>
        <path
          d="M109.033 115.597C109.033 107.366 105.763 99.4732 99.9434 93.6535C94.1237 87.8338 86.2305 84.5644 78.0003 84.5644C69.77 84.5644 61.8768 87.8338 56.0571 93.6535C50.2375 99.4732 46.968 107.366 46.968 115.597H109.033Z"
          fill="#452015"
          stroke="#452015"
          stroke-width="8"
          stroke-linejoin="round"
        />
        <path
          d="M50.8466 71.4354C55.5037 71.4354 59.279 65.8245 59.279 58.9031C59.279 51.9818 55.5037 46.3709 50.8466 46.3709C46.1895 46.3709 42.4142 51.9818 42.4142 58.9031C42.4142 65.8245 46.1895 71.4354 50.8466 71.4354Z"
          fill="#452015"
        />
        <path
          d="M103.363 71.4354C108.02 71.4354 111.796 65.8245 111.796 58.9031C111.796 51.9818 108.02 46.3709 103.363 46.3709C98.7061 46.3709 94.9308 51.9818 94.9308 58.9031C94.9308 65.8245 98.7061 71.4354 103.363 71.4354Z"
          fill="#452015"
        />
        <path
          d="M32.2328 51.5743C36.057 42.0997 36.4738 33.336 33.1638 32C29.8538 30.664 24.0704 37.2617 20.2463 46.7363C16.4221 56.2109 16.0053 64.9747 19.3153 66.3106C22.6253 67.6466 28.4087 61.049 32.2328 51.5743Z"
          fill="#FCE4DB"
        />
        <path
          d="M19.5878 77.091C20.2681 73.7617 19.4244 70.7777 17.7033 70.426C15.9822 70.0742 14.0353 72.488 13.355 75.8172C12.6746 79.1465 13.5183 82.1305 15.2395 82.4823C16.9606 82.834 18.9074 80.4202 19.5878 77.091Z"
          fill="#FCE4DB"
        />
        <defs>
          <filter
            id="filter0_d_8_30479"
            x="0"
            y="0"
            width="156"
            height="166"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.270588 0 0 0 0 0.12549 0 0 0 0 0.0823529 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_8_30479"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_8_30479"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </g>
    <g v-if="props.face === 'upset'" class="emote__face">
      <svg
        preserveAspectRatio="none"
        viewBox="0 0 156 166"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_8_30486)">
          <path
            d="M78 152C118.869 152 152 118.869 152 78C152 37.1309 118.869 4 78 4C37.1309 4 4 37.1309 4 78C4 118.869 37.1309 152 78 152Z"
            fill="#FFD056"
          />
          <path
            d="M78 152C118.869 152 152 118.869 152 78C152 37.1309 118.869 4 78 4C37.1309 4 4 37.1309 4 78C4 118.869 37.1309 152 78 152Z"
            stroke="#452015"
            stroke-width="8"
            stroke-miterlimit="10"
          />
        </g>
        <path
          d="M109.033 115.597C109.033 107.366 105.763 99.4732 99.9434 93.6535C94.1237 87.8338 86.2305 84.5644 78.0003 84.5644C69.77 84.5644 61.8768 87.8338 56.0572 93.6535C50.2375 99.4732 46.968 107.366 46.968 115.597"
          stroke="#452015"
          stroke-width="8"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M50.8466 71.4354C55.5037 71.4354 59.279 65.8245 59.279 58.9031C59.279 51.9818 55.5037 46.3709 50.8466 46.3709C46.1895 46.3709 42.4142 51.9818 42.4142 58.9031C42.4142 65.8245 46.1895 71.4354 50.8466 71.4354Z"
          fill="#452015"
        />
        <path
          d="M103.363 71.4354C108.02 71.4354 111.796 65.8245 111.796 58.9031C111.796 51.9818 108.02 46.3709 103.363 46.3709C98.7061 46.3709 94.9308 51.9818 94.9308 58.9031C94.9308 65.8245 98.7061 71.4354 103.363 71.4354Z"
          fill="#452015"
        />
        <path
          d="M32.2413 51.5793C36.0654 42.1046 36.4822 33.3409 33.1722 32.0049C29.8622 30.6689 24.0788 37.2666 20.2547 46.7412C16.4305 56.2158 16.0137 64.9796 19.3237 66.3156C22.6338 67.6515 28.4171 61.0539 32.2413 51.5793Z"
          fill="#FCE4DB"
        />
        <path
          d="M19.6028 77.0892C20.2831 73.7599 19.4394 70.7759 17.7183 70.4241C15.9972 70.0724 14.0504 72.4862 13.37 75.8154C12.6896 79.1447 13.5333 82.1287 15.2545 82.4804C16.9756 82.8322 18.9224 80.4184 19.6028 77.0892Z"
          fill="#FCE4DB"
        />
        <defs>
          <filter
            id="filter0_d_8_30486"
            x="0"
            y="0"
            width="156"
            height="166"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.270588 0 0 0 0 0.12549 0 0 0 0 0.0823529 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_8_30486"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_8_30486"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </g>
    <g v-if="props.face === 'neutral'" class="emote__face">
      <svg
        preserveAspectRatio="none"
        viewBox="0 0 156 166"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_8_30493)">
          <path
            d="M78 152C118.869 152 152 118.869 152 78C152 37.1309 118.869 4 78 4C37.1309 4 4 37.1309 4 78C4 118.869 37.1309 152 78 152Z"
            fill="#3EC3EE"
          />
          <path
            d="M78 152C118.869 152 152 118.869 152 78C152 37.1309 118.869 4 78 4C37.1309 4 4 37.1309 4 78C4 118.869 37.1309 152 78 152Z"
            stroke="#452015"
            stroke-width="8"
            stroke-miterlimit="10"
          />
        </g>
        <path
          d="M50.8467 71.4354C55.5038 71.4354 59.2791 65.8245 59.2791 58.9031C59.2791 51.9818 55.5038 46.3709 50.8467 46.3709C46.1896 46.3709 42.4143 51.9818 42.4143 58.9031C42.4143 65.8245 46.1896 71.4354 50.8467 71.4354Z"
          fill="#452015"
        />
        <path
          d="M103.363 71.4354C108.02 71.4354 111.796 65.8245 111.796 58.9031C111.796 51.9818 108.02 46.3709 103.363 46.3709C98.706 46.3709 94.9307 51.9818 94.9307 58.9031C94.9307 65.8245 98.706 71.4354 103.363 71.4354Z"
          fill="#452015"
        />
        <path
          d="M32.2304 51.577C36.0546 42.1023 36.4714 33.3386 33.1614 32.0026C29.8513 30.6666 24.068 37.2643 20.2438 46.7389C16.4197 56.2136 16.0029 64.9773 19.3129 66.3133C22.6229 67.6493 28.4063 61.0516 32.2304 51.577Z"
          fill="#FCE4DB"
        />
        <path
          d="M19.5769 77.0842C20.2573 73.755 19.4136 70.771 17.6924 70.4192C15.9713 70.0675 14.0245 72.4812 13.3441 75.8105C12.6637 79.1398 13.5075 82.1238 15.2286 82.4755C16.9497 82.8273 18.8965 80.4135 19.5769 77.0842Z"
          fill="#FCE4DB"
        />
        <path
          d="M46.9617 100.081H109.408"
          stroke="#452015"
          stroke-width="8"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <defs>
          <filter
            id="filter0_d_8_30493"
            x="0"
            y="0"
            width="156"
            height="166"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.270588 0 0 0 0 0.12549 0 0 0 0 0.0823529 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_8_30493"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_8_30493"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </g>
    <g v-if="props.face === 'happy'" class="emote__face">
      <svg
        preserveAspectRatio="none"
        viewBox="0 0 156 166"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_8_30500)">
          <path
            d="M78 152C118.869 152 152 118.869 152 78C152 37.1309 118.869 4 78 4C37.1309 4 4 37.1309 4 78C4 118.869 37.1309 152 78 152Z"
            fill="#CCE631"
          />
          <path
            d="M78 152C118.869 152 152 118.869 152 78C152 37.1309 118.869 4 78 4C37.1309 4 4 37.1309 4 78C4 118.869 37.1309 152 78 152Z"
            stroke="#452015"
            stroke-width="8"
            stroke-miterlimit="10"
          />
        </g>
        <path
          d="M46.9673 84.5644C46.9673 92.7947 50.2368 100.688 56.0564 106.508C61.8761 112.327 69.7693 115.597 77.9995 115.597C86.2298 115.597 94.123 112.327 99.9426 106.508C105.762 100.688 109.032 92.7947 109.032 84.5644"
          stroke="#452015"
          stroke-width="8"
          stroke-miterlimit="10"
          stroke-linecap="round"
        />
        <path
          d="M50.847 71.4354C55.5041 71.4354 59.2794 65.8245 59.2794 58.9031C59.2794 51.9818 55.5041 46.3709 50.847 46.3709C46.1899 46.3709 42.4146 51.9818 42.4146 58.9031C42.4146 65.8245 46.1899 71.4354 50.847 71.4354Z"
          fill="#452015"
        />
        <path
          d="M103.363 71.4354C108.02 71.4354 111.795 65.8245 111.795 58.9031C111.795 51.9818 108.02 46.3709 103.363 46.3709C98.7055 46.3709 94.9302 51.9818 94.9302 58.9031C94.9302 65.8245 98.7055 71.4354 103.363 71.4354Z"
          fill="#452015"
        />
        <path
          d="M32.2263 51.5758C36.0504 42.1012 36.4672 33.3375 33.1572 32.0015C29.8472 30.6655 24.0638 37.2632 20.2397 46.7378C16.4155 56.2124 15.9987 64.9761 19.3087 66.3121C22.6187 67.6481 28.4021 61.0504 32.2263 51.5758Z"
          fill="#FCE4DB"
        />
        <defs>
          <filter
            id="filter0_d_8_30500"
            x="0"
            y="0"
            width="156"
            height="166"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.270588 0 0 0 0 0.12549 0 0 0 0 0.0823529 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_8_30500"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_8_30500"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </g>
    <g v-if="props.face === 'very-happy'" class="emote__face">
      <svg
        preserveAspectRatio="none"
        viewBox="0 0 156 166"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_8_30506)">
          <path
            d="M78 152C118.869 152 152 118.869 152 78C152 37.1309 118.869 4 78 4C37.1309 4 4 37.1309 4 78C4 118.869 37.1309 152 78 152Z"
            fill="#5BD946"
          />
          <path
            d="M78 152C118.869 152 152 118.869 152 78C152 37.1309 118.869 4 78 4C37.1309 4 4 37.1309 4 78C4 118.869 37.1309 152 78 152Z"
            stroke="#452015"
            stroke-width="8"
            stroke-miterlimit="10"
          />
        </g>
        <path
          d="M46.9673 84.5644C46.9673 92.7947 50.2368 100.688 56.0564 106.508C61.8761 112.327 69.7693 115.597 77.9995 115.597C86.2298 115.597 94.123 112.327 99.9426 106.508C105.762 100.688 109.032 92.7947 109.032 84.5644H46.9673Z"
          fill="#452015"
          stroke="#452015"
          stroke-width="8"
          stroke-linejoin="round"
        />
        <path
          d="M50.847 71.4354C55.5041 71.4354 59.2794 65.8245 59.2794 58.9031C59.2794 51.9818 55.5041 46.3709 50.847 46.3709C46.1899 46.3709 42.4146 51.9818 42.4146 58.9031C42.4146 65.8245 46.1899 71.4354 50.847 71.4354Z"
          fill="#452015"
        />
        <path
          d="M103.363 71.4354C108.02 71.4354 111.795 65.8245 111.795 58.9031C111.795 51.9818 108.02 46.3709 103.363 46.3709C98.7055 46.3709 94.9302 51.9818 94.9302 58.9031C94.9302 65.8245 98.7055 71.4354 103.363 71.4354Z"
          fill="#452015"
        />
        <path
          d="M32.2241 51.5709C36.0482 42.0963 36.465 33.3326 33.155 31.9966C29.845 30.6606 24.0616 37.2582 20.2375 46.7329C16.4133 56.2075 15.9965 64.9712 19.3065 66.3072C22.6165 67.6432 28.3999 61.0455 32.2241 51.5709Z"
          fill="#FCE4DB"
        />
        <path
          d="M19.5531 77.0817C20.2335 73.7524 19.3898 70.7684 17.6686 70.4167C15.9475 70.065 14.0007 72.4787 13.3203 75.808C12.6399 79.1372 13.4836 82.1213 15.2048 82.473C16.9259 82.8247 18.8727 80.4109 19.5531 77.0817Z"
          fill="#FCE4DB"
        />
        <defs>
          <filter
            id="filter0_d_8_30506"
            x="0"
            y="0"
            width="156"
            height="166"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="10" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.270588 0 0 0 0 0.12549 0 0 0 0 0.0823529 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_8_30506"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_8_30506"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    face: String,
    fill: String,
  },
};
</script>
